<template>
  <div>
    <a-card title="库区管理">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="编号, 名称, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="openFormModal({})">新增库区</a-button>
        </div>
      </a-row>

      <div>
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          :scroll="{ y: 480 }"
          @change="onChangeTable"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? "激活" : "冻结" }}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button icon="edit" @click="openFormModal(item)">编辑</a-button>
              <a-popconfirm title="确定删除吗?" @confirm="destroy(item)">
                <a-button type="danger" icon="delete">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </div>
    </a-card>

    <form-modal v-model="visible" :item="targetItem" />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import { reservoirAreaList, reservoirAreaDestroy } from "@/api/data";
import { warehouseOption } from "@/api/option";
import { columns } from "./columns";

export default {
  components: {
    FormModal: () => import("./formModal"),
  },
  data() {
    return {
      columns,
      pagination: { current: 1, total: 0, pageSize: 48, showTotal: (total) => `共 ${total} 条` },
      searchForm: { page: 1, page_size: 16 },
      loading: false,
      visible: false,
      importLoading: false,
      items: [],
      targetItem: {},
      warehouseItems: [],
    };
  },
  methods: {
    initData() {
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      this.loading = true;
      reservoirAreaList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    destroy(item) {
      reservoirAreaDestroy({ id: item.id }).then(() => {
        this.items = this.$functions.removeItem(this.items, item);
        this.$message.success("删除成功");
      });
    },
    openFormModal(item) {
      this.targetItem = { ...item };
      this.visible = true;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
