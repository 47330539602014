import request from "@/utils/request";


// ReservoirArea
export function reservoirAreaList(params) {
  return request({ url: `/reservoir_areas/`, method: "get", params });
}

export function reservoirAreaCreate(data) {
  return request({ url: `/reservoir_areas/`, method: "post", data });
}

export function reservoirAreaUpdate(data) {
  return request({ url: `/reservoir_areas/${data.id}/`, method: "put", data });
}

export function reservoirAreaDestroy(data) {
  return request({ url: `/reservoir_areas/${data.id}/`, method: "delete", data });
}

export function getReservoirAreasNumber(params) {
  return request({ url: `/reservoir_areas/number/`, method: "get", params });
}

// Material
export function getMaterialsNumber(params) {
  return request({ url: `/materials/number/`, method: "get", params });
}

export function materialList(params) {
  return request({ url: `/materials/`, method: "get", params });
}

export function materialCreate(data) {
  return request({ url: `/materials/`, method: "post", data });
}

export function materialUpdate(data) {
  return request({ url: `/materials/${data.id}/`, method: "put", data });
}

export function materialDestroy(data) {
  return request({ url: `/materials/${data.id}/`, method: "delete", data });
}

export function materialImageUpload(data) {
  return request({
    url: "/material_images/",
    method: "post",
    data,
  });
}

export function materialsExport(params) {
  return request({
    url: '/materials/export/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

export function materialsTemplate(params) {
  return request({
    url: '/materials/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

export function materialsImport(data) {
  return request({
    url: '/materials/import_data/',
    method: 'post',
    data,
  })
}

export function materialsImportUpdate(data) {
  return request({
    url: '/materials/import_update/',
    method: 'post',
    data,
  })
}